import { AuthenticationActions } from '$/app/authentication/authentication.actions';
import { AuthManagementApiService, OverlayService } from '$/app/services';
import { AlcContactSupportComponent } from '$/app/shared/components/contact-support.component';
import { AlcPasswordInputComponent } from '$/app/shared/components/password-input/password-input.component';
import { AlcVersionMarkerComponent } from '$/app/shared/components/version-marker/version-marker.component';
import { SharedModule } from '$/app/shared/shared.module';
import { validateForm } from '$/app/utils/validate-form';
import { Logger } from '$shared/logger';
import { SignupData, ValidateSignupTokenResult } from '$shared/types/auth';
import { Result } from '$shared/types/result';
import { Component, effect, inject, input, signal } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { isString, omit } from 'lodash';

type Status =
  | 'Loading'
  | 'Missing'
  | 'InvitationSent'
  | 'UnknownError'
  | ValidateSignupTokenResult['status'];

@Component({
    selector: 'alc-signup-page',
    templateUrl: './signup.page.html',
    imports: [
        SharedModule,
        AlcVersionMarkerComponent,
        AlcContactSupportComponent,
        AlcPasswordInputComponent
    ]
})
export class SignupPage {
  private readonly fb = inject(UntypedFormBuilder);
  private readonly overlay = inject(OverlayService);
  private readonly store = inject(Store);
  private readonly authManagementService = inject(AuthManagementApiService);

  protected readonly form = this.fb.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    password: ['', Validators.required],
    confirmPassword: ['', Validators.required]
  });

  public readonly facilityId = input<string | null>();
  public readonly token = input<string | null>();

  protected readonly status = signal<Status>('Loading');

  #_effects = [
    effect(async () => {
      try {
        const result = await this.validateToken();

        if (result.status === 'Valid') {
          this.form.controls.firstName.setValue(result.user.firstName);
          this.form.controls.lastName.setValue(result.user.lastName);
        }

        this.status.set(result.status);
      } catch (error) {
        Logger.error('Error checking token status', error);
        this.status.set('UnknownError');
      }
    })
  ];

  protected async requestNewInvitation() {
    await this.authManagementService.requestNewInvitation({
      signupToken: this.token(),
      facilityId: this.facilityId()
    });

    this.status.set('InvitationSent');
  }

  protected async submit() {
    using loading = this.overlay.loading('Completing signup...', {
      disableAutoHide: true
    });

    const result = this.validateForm();

    if (result.isErr()) {
      return;
    }

    const signupData = result.unwrap();

    try {
      loading.show();
      this.store.dispatch(AuthenticationActions.signup());
      const loginData =
        await this.authManagementService.completeSignup(signupData);

      this.store.dispatch(
        AuthenticationActions.signupSuccess({
          loginData,
          params: {
            onComplete: () => loading.hide()
          }
        })
      );
    } catch (error) {
      Logger.error('Signup failed', { error });

      loading.hide();

      this.store.dispatch(AuthenticationActions.signupFail({ error }));

      this.overlay.showErrorMessage(error, { title: 'Signup Failed' });
    }
  }

  private validateForm(): Result<SignupData> {
    try {
      const formData = validateForm<SignupData>(this.form);

      return Result.OK({
        ...omit(formData, 'confirmPassword'),
        facilityId: this.facilityId(),
        signupToken: this.token()
      });
    } catch (error) {
      this.overlay.showToast('failure', 'Please fix the invalid fields');

      return Result.Err(error);
    }
  }

  private async validateToken(): Promise<
    ValidateSignupTokenResult | { status: Exclude<Status, 'Valid'> }
  > {
    const signupToken = this.token();

    if (!isString(signupToken)) {
      Logger.warn('Signup token missing');

      return { status: 'Missing' };
    }

    try {
      const result = await this.authManagementService.validateSignupToken({
        signupToken
      });

      Logger.debug('Token validation result', {
        signupToken,
        result
      });

      return result;
    } catch (error) {
      return { status: 'UnknownError' };
    }
  }
}
