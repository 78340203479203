import { UnreadAnnouncementSelectors } from '$/app/shared/pages/modals/unread-announcements/unread-announcement.selectors';
import { AlcUnreadAnnouncementsComponent } from '$/app/shared/pages/modals/unread-announcements/unread-announcements.component';
import { CUSTOM_MATERIAL_ICONS } from '$/config/custom-material-icons';
import { updateSplashScreenText } from '$/lib/splash-screen';
import { Logger } from '$shared/logger';
import { IPushNotificationData } from '$shared/notifications';
import { backgroundTask } from '$shared/utils';
import { AfterViewInit, Component, NgZone, inject } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { App } from '@capacitor/app';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token
} from '@capacitor/push-notifications';
import { IonApp, Platform } from '@ionic/angular/standalone';
import { Store } from '@ngrx/store';
import { AuthenticationService } from './authentication/authentication.service';
import { NotificationMenuPageActions } from './pages/facility-workspace/notification-menu/notification-menu.actions';
import { AppInfo, OverlayService } from './services';
import { SharedModule } from './shared/shared.module';
import { NotificationsPushActions } from './store/notifications';
import { UserApiActions } from './store/user/actions/user-api.actions';
import { UserSelectors } from './store/user/user.selectors';

@Component({
    selector: 'alc-app-root',
    imports: [SharedModule, IonApp],
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent implements AfterViewInit {
  private readonly store = inject(Store);
  private readonly router = inject(Router);
  private readonly ngZone = inject(NgZone);
  private readonly overlay = inject(OverlayService);
  private readonly platform = inject(Platform);
  private readonly authenticationService = inject(AuthenticationService);

  private readonly unreadAnnouncements = this.store.selectSignal(
    UnreadAnnouncementSelectors.selectAnnouncementsCards
  );

  protected readonly isAuthenticated = this.store.selectSignal(
    UserSelectors.selectIsAuthenticated
  );

  protected readonly facilityId = this.store.selectSignal(
    UserSelectors.selectFacilityId
  );

  protected readonly userId = this.store.selectSignal(
    UserSelectors.selectUserId
  );

  constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
    Logger.info(`Alcomy v${AppInfo.version} (${AppInfo.buildNumber})`);
    updateSplashScreenText('Loading page...');

    App.addListener('appUrlOpen', (data: any) => {
      this.ngZone.run(() => {
        const route = (data.url as string).split('.com').pop();
        if (route) {
          this.router.navigateByUrl(route);
        }
      });
    });

    window.addEventListener('online', () => {
      Logger.info('Online');

      return this.overlay.hideLoading();
    });

    window.addEventListener('offline', () => {
      Logger.warn('Offline');

      this.overlay.showLoading(
        'You are offline. Please check your connection.',
        {
          mode: 'md',
          keyboardClose: false
        }
      );
    });

    CUSTOM_MATERIAL_ICONS.forEach((icon) => {
      iconRegistry.addSvgIcon(
        icon.name,
        sanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    });

    this.onAppResume(() => this.showUnreadAnnouncements());
  }

  ngAfterViewInit() {
    if (AppInfo.deviceInfo.platform !== 'web') {
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (action: ActionPerformed) => {
          const data = action.notification.data as IPushNotificationData;
          if (data?.url === undefined) {
            Logger.warn(
              'Unable to perform notification action, url not present',
              { action }
            );
          } else {
            this.handlePushNotificationAction(data);
          }
        }
      );

      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          const data = notification.data as IPushNotificationData;

          this.ngZone.run(() => {
            this.store.dispatch(
              NotificationsPushActions.notificationReceived({
                data
              })
            );
          });
        }
      );

      PushNotifications.addListener('registration', (token: Token) =>
        this.store.dispatch(
          UserApiActions.saveFCMToken({
            userId: this.userId(),
            fcmToken: token.value
          })
        )
      );

      PushNotifications.addListener('registrationError', (error) => {
        Logger.error('Error on PushNotification registration', error);
      });
    }
  }

  private handlePushNotificationAction({
    url,
    id,
    facilityId
  }: IPushNotificationData) {
    Logger.debug('Push notification action navigating to url', { url });

    this.ngZone.run(async () => {
      if (!this.isAuthenticated()) {
        Logger.debug('Not logged in, unable to change facility');

        await this.router.navigateByUrl('/login');

        return;
      }

      if (facilityId !== this.facilityId()) {
        await this.authenticationService.switchToFacility({
          facilityId,
          url
        });
      } else {
        await this.router.navigateByUrl(url);
      }

      this.store.dispatch(NotificationMenuPageActions.readNotification(id));
    });
  }

  private onAppResume(callback: () => any) {
    const invokeCallback = () =>
      backgroundTask('onAppResume callback', async () => {
        try {
          await callback();
        } catch (error) {
          Logger.error('Error in onAppResume callback', { error });
        }
      });

    if (AppInfo.deviceInfo.platform === 'web') {
      document.addEventListener('visibilitychange', () => {
        if (!document.hidden) {
          invokeCallback();
        }
      });
    } else {
      this.platform.resume.subscribe(() => {
        invokeCallback();
      });
    }
  }

  private showUnreadAnnouncements() {
    const unreadAnnouncements = this.unreadAnnouncements();

    if (!unreadAnnouncements.length) {
      return;
    }

    this.overlay.showModalSingleton({
      component: AlcUnreadAnnouncementsComponent,
      cssClass: 'stacked'
    });
  }
}
